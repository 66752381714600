<template>
<v-layout>
    <v-list-item three-line class="bottom-border">
        <v-list-item-content>
            <v-layout wrap>
                <v-flex xs12 my-4>
                    <span v-for="item in data.transactions" :key="item._id">
                        <v-layout my-2>
                            <v-flex xs10>
                                <v-list-item-title >{{item.narration}}</v-list-item-title>
                                <v-list-item-subtitle class="overline">{{item.txDate|dateFormat}}</v-list-item-subtitle>
                            </v-flex>
                            <v-flex text-right xs3>
                                <v-list-item-title v-if="item.txType=='CR'" class="subtitle-2">₹{{item.txAmount}}/-</v-list-item-title>
                                <v-list-item-title v-else-if="item.txType=='DR'" class="subtitle-2 expense" style="color:red">-₹{{item.txAmount}}/-</v-list-item-title>
                            </v-flex>
                        </v-layout>
                    </span>
                </v-flex>
                <v-flex xs12 my-2>
                    <v-layout>
                        <v-flex xs12>
                            <label class="body-2">Income (₹) <label class="body-2 income">{{data.income}}/-</label></label>
                        </v-flex>
                        <v-flex xs6>
                            <label class="body-2 text-right">Expense (₹) <label class="body-2 text-right expense">- {{data.expense}}/-</label></label>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-list-item-content>
    </v-list-item>
</v-layout>
</template>

<script>
import moment from 'moment'
export default {
    props: ["data"],
    data() {
        return {
            date: '',
            year: '',
        }
    },
    mounted() {
        this.initcomponent();
    },
    methods: {
        initcomponent() {
            this.date = moment(this.data.date).format('DD-MMM-YY')
            this.year = moment(this.data.date).format('YYYY')
        }
    },
};
</script>

<style lang="scss" scoped>
.bottom-border {
    border-bottom: 1px solid gray;
}
.expense{
    color: red;
}
.income{
    color: green;
}
</style>
